import React, { Component } from 'react';
import { connect } from "react-redux";
import {resetOpsState} from '../../actions/customCheckinActions';
import { setLocation } from "../../actions/userFeedbackActions";
import HeaderContent from '../common/Header/HeaderContent';
import VerticalTabs from './VerticalTabs';
import './ops.css'
class Operations extends Component {
    constructor(props){
        super(props)
    }
    componentDidMount(){
        this.props.resetOpsState();
        this.props.setLocation("TdmOps")
    }
    render() {
        return(
                <div className="white">
                    <HeaderContent
                    title={`TDM Operations Support`}
                    icon="cogs ops-icon"
                    empty={true}
                    loadingText="Loading operational support..."></HeaderContent>
                    <VerticalTabs resetOpsState={this.props.resetOpsState} selectedEnvValue={this.props.selectedEnvValue}/>
                </div>
        )
    }
}
const mapStateToProps = state => {
    return {
        assets : state.customCheckin.assets,
        selectedEnvValue: state.auth.selectedEnvValue
    };
};

const mapDispatchToProps = dispatch => {
    return {
        resetOpsState: () => dispatch(resetOpsState()),
        setLocation: (path) => dispatch(setLocation(path))
    };
  };
export default connect(mapStateToProps,mapDispatchToProps)(Operations);