import React from 'react';
import { Button } from '@mui/material';
import { CSVLink } from "react-csv";

const DownloadButton = ({ csvData, csvData2, accountNum }) => {
    function removeNullValues(obj) {
        // Create a new object to store the filtered properties
        const result = {};

        // Iterate over the keys of the object
        for (const key in obj) {
            // Check if the property is not null
            if (obj[key] !== null) {
                result[key] = obj[key];  // Add it to the result object
            }
        }

        return result;
    }
    const csvDataCovert = (type = "value") => {
        let obj = {
            ...csvData,
            ...csvData2,
            accountNumber: accountNum
        }
        console.log(obj)
        const cleanedObject = removeNullValues(obj);
        let result = Object.keys(cleanedObject);
        let result2 = Object.values(cleanedObject);
        let result2WithStr = result2.map((_item) => `'${_item}'`)
        if (type === "key") {
            return result
        } return result2WithStr;
    }
    const csvDataCovertData = [
        csvDataCovert("key"),
        csvDataCovert()
    ];

    return (
        <Button variant="outlined" color="primary" style={{ marginLeft: "670px", marginTop: "20px" }}>
            <CSVLink data={csvDataCovertData} filename={"micaForm.csv"}>Download CSV</CSVLink>
        </Button>
    );
};

export default DownloadButton;
