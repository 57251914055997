import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import TabPanel from '../common/Tabs/TabPanel';
import CustomCheckin from './CustomCheckin';
import SimasInsert from './SimasInsert';
import MicaForm from './MicaForm';
import InputOutlinedIcon from '@material-ui/icons/InputOutlined';
import UpdateOutlined from '@material-ui/icons/UpdateOutlined';
import SettingsInputAntennaOutlinedIcon from '@material-ui/icons/SettingsInputAntennaOutlined';
import UnmsUtensil from './UnmsUtensil';

import { green } from '@material-ui/core/colors';

function a11yProps(index) {
  return {
    id: `vertical-tab-${index}`,
    'aria-controls': `vertical-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.paper,
    display: 'flex'
  },
  tabs: {
    borderRight: `1px solid ${theme.palette.divider}`,
  },
  tab : {
    verticalAlign: 'middle',
    fontWeight:'bold',
    backgroundColor:"aliceblue"
  },
  tabpanel: {
        width : '100%'
  }
}));

export default function VerticalTabs(props) {
  const classes = useStyles();
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
    props.resetOpsState();
  };

  return (
    <div className={classes.root}>
            <div className="col-sm-2">
                <Tabs
                    orientation="vertical"
                    variant="scrollable"
                    value={value}
                    onChange={handleChange}
                    aria-label="Vertical tabs"
                    className={classes.tabs}>
                      
                        <Tab className = {classes.tab} label={<div> <SettingsInputAntennaOutlinedIcon style={{color: green[500]}} /> 
                          <br/>Custom Check-in</div>} {...a11yProps(0)} />
                     
                        <Tab  className = {classes.tab} label={<div><InputOutlinedIcon color="secondary"/>
                          <br/>Mobile Preseeding</div>} {...a11yProps(1)} />

                        <Tab  className = {classes.tab} label={<div><UpdateOutlined style={{"color":"blue"}}/>
                          <br/>UNMS Insert</div>} {...a11yProps(2)} />
                      
                        <Tab className = {classes.tab} label={<div><InputOutlinedIcon color="secondary"/>
                          <br/>SIMaS Insert</div>} {...a11yProps(3)} />
                      
                      <Tab className = {classes.tab} label={<div><SettingsInputAntennaOutlinedIcon style={{"color":"blue"}}/>
                        <br/>MICA</div>} {...a11yProps(4)} />
                </Tabs>
            </div>
            <div className="col-sm-10">
                <TabPanel className={classes.tabpanel} value={value} index={0}>
                    <CustomCheckin />
                </TabPanel>
                <TabPanel className={classes.tabpanel} value={value} index={1}>
                    Mobile Preseeeding
                </TabPanel>
                <TabPanel className={classes.tabpanel} value={value} index={2}>
                    <UnmsUtensil />
                </TabPanel>
                <TabPanel className={classes.tabpanel} value={value} index={3}>
                    <SimasInsert/>
                </TabPanel>
                <TabPanel className={classes.tabpanel} value={value} index={4}>
                    <MicaForm selectedEnvValue={props.selectedEnvValue}/>
                </TabPanel>
            </div>
    </div>
  );
}